import React, { Component} from 'react';
import './PhoneTable.css';
import Modal from 'react-modal';
import * as PhoneService from '../../services/PhoneService.js';
import * as DialService from '../../services/DialService.js';
import * as InconcertServiceSinBaragent from '../../services/InconcertServiceSinBaragent.js';
import * as ClientService from '../../services/ClientService.js';
import * as Net2PhoneService from '../../services/Net2PhoneService.js'
import * as EmailService from '../../services/EmailService.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCopy } from "react-icons/fa";
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    height                : '250px',
    fontSize            : '18px',    
    backgroundColor: '#a3a0a0',
    color: '#000000'
  }
};
Modal.setAppElement('body');
class PhoneTable extends Component {
  constructor(props, context) {
    super(props, context)  
    this.state = {
      modalSave: false,
      modalMessage: false,        
      message: "",  
      btnSave: true,
      data: [],
      fields: {},
      errors: {}
    };
    this.handleInputChange = this.handleInputChange.bind(this);  
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }


  async  componentWillMount() {
    window.onmessage = function(e) {
      var responseParent = JSON.parse(e.data);
      this.setState({data: responseParent.data});
    }.bind(this);
    await PhoneService.phoneScore(this.props.idCuenta, this.props.paisEmpresaOrigen).then(async function(response) {
    this.setState({data: response});
    // var havePhone;
    // if (response.length > 0) {
    //   havePhone = true;
    // }else{
    //   havePhone = false;
    // }
    // var parent = window.parent;
    // parent.postMessage(JSON.stringify({"havePhone": havePhone}), "*");
    }.bind(this)).catch((error) => {
      this.openModal('modalMessage');
      console.log(error);
      this.setState({message: "Servicio no disponible"});
    });
  }
  handleInputChange(event) {        
    let data = this.state.data;    
    data.forEach(item => {
       if (item.number === event.target.value){              
        item.active =  event.target.checked       
       }        
    })    
    this.setState({data: data});
    this.updatePhone(event.target.value);
  }
  openModal(modal) {
    switch (modal) {
      case 'modalSave':
        this.setState({modalSave: true});                  
        break;
      case 'modalMessage':
          this.setState({modalMessage: true});            
            break;
      default:
        break;
    }    
  } 
  afterOpenModal() {    
    //this.subtitle.style.color = '#000000';    
  } 
  closeModal(modal) {    
    switch (modal) {
      case 'modalSave':
        this.setState({modalSave: false});  
        break;
      case 'modalMessage':
        this.setState({modalMessage: false});  
          break;
      default:
        break;
    }
  }
  async callDial(number, uri){                 
    var result = this.state.data.find(function(element) {      
      return element.status_call === false;
    });    
    if (result === undefined) {      
      var result = this.state.data.filter(item => item.number === number);
      result.map((element) => {      
        return element.status_call = false;
      });
      uri = uri+"&phoneNumber="+number;
      var body = {"uri": uri};      
      var responseCall = await DialService.callDial(body);
      var jsonResponse = JSON.stringify(responseCall);
      console.log(jsonResponse);        
      var parent = window.parent;  
      this.setState({message: "Marcando al Dial..."});
      this.openModal('modalMessage');       
      parent.postMessage(JSON.stringify({"number": number,"data": this.state.data, "responseDial": jsonResponse, "havePhone": true}), "*");
    }else{
      this.setState({message: "Por favor genere una gestión para poder continuar operando."});
      this.openModal('modalMessage');
    }            
  }
  selectPhone = (number) => {
    let result = this.state.data.find(function(element) {
      return element.status_call === false;
    });
    if (result === undefined) {
      result = this.state.data.filter(item => item.number === number);
      result.map((element) => {
        return element.status_call = false;
      });
      this.setState({message: "Se selecciono el teléfono "+number});
      this.openModal('modalMessage');
      const jsonResponse = {};
      window.parent.postMessage(JSON.stringify({"number": number,"data": this.state.data, "responseDial": jsonResponse, "havePhone": true}), "*");
    }else{
      this.setState({message: "Por favor genere una gestión para poder continuar operando."});
      this.openModal('modalMessage');
    }
  }
  async callInconcert(number, uri){                 
    var result = this.state.data.find(function(element) {      
      return element.status_call === false;
    });    
    if (result === undefined) {      
      var result = this.state.data.filter(item => item.number === number);
      result.map((element) => {      
        return element.status_call = false;
      });      
      var parent = window.parent;  
      var dataIml = "zwidCuentaw"+this.props.idCuenta;
      var data = {campaign:this.props.inconcertCampaign,number:number,imlMakeCall:dataIml};
      var responseCall = InconcertServiceSinBaragent.callInconcert(data);
      this.setState({message: "Marcando en Inconcert..."});
      this.openModal('modalMessage');       
      parent.postMessage(JSON.stringify({"number": number,"data": this.state.data, "responseDial": responseCall, "havePhone": true}), "*");
      setTimeout(()=>this.closeModal('modalMessage'), 2000);
    }else{
      this.setState({message: "Por favor genere una gestión para poder continuar operando."});
      this.openModal('modalMessage');
    }            
  }

  callNet2Phone = async(number, typePhone) => {
    let result = this.state.data.find(function(element) {
      return element.status_call === false;
    });
    if (result === undefined) {
      result = this.state.data.filter(item => item.number === number);
      result.map((element) => {
        return element.status_call = false;
      });

      //Formatear numero para net2phone
      //Linea 011 . Quitar el 011
      //01147183243 = 47183243
      //Linea y no 011 . Dejar como esta
      //02614415500 = 02614415500
      //Movil  011: Quitar 011 y reemplazar por 15
      //01136899111 = 1536899111
      //Movil Nacional o no 011 : Entre el prefijo y el numero colocar 15
      //02611234567 = 0261151234567
      let formatNet2Phone = '';
      if(number.substring(0,3) == '011'){
        if(typePhone == 'Movil') {
          formatNet2Phone = "15" + number.substring(number.length - 8);
        } else if(typePhone == 'Linea'){
          formatNet2Phone = number.substring(number.length - 8);
        }
      }else {
        if(typePhone == 'Linea'){
          formatNet2Phone = number;
        }else if(typePhone == 'Movil') {
          if(
              number.substring(0,5) == '02021' ||
              number.substring(0,5) == '02202' ||
              number.substring(0,5) == '02223' ||
              number.substring(0,5) == '02224' ||
              number.substring(0,5) == '02225' ||
              number.substring(0,5) == '02226' ||
              number.substring(0,5) == '02227' ||
              number.substring(0,5) == '02229' ||
              number.substring(0,5) == '02241' ||
              number.substring(0,5) == '02242' ||
              number.substring(0,5) == '02243' ||
              number.substring(0,5) == '02244' ||
              number.substring(0,5) == '02245' ||
              number.substring(0,5) == '02246' ||
              number.substring(0,5) == '02252' ||
              number.substring(0,5) == '02254' ||
              number.substring(0,5) == '02255' ||
              number.substring(0,5) == '02257' ||
              number.substring(0,5) == '02261' ||
              number.substring(0,5) == '02262' ||
              number.substring(0,5) == '02264' ||
              number.substring(0,5) == '02265' ||
              number.substring(0,5) == '02266' ||
              number.substring(0,5) == '02267' ||
              number.substring(0,5) == '02268' ||
              number.substring(0,5) == '02271' ||
              number.substring(0,5) == '02272' ||
              number.substring(0,5) == '02273' ||
              number.substring(0,5) == '02274' ||
              number.substring(0,5) == '02281' ||
              number.substring(0,5) == '02283' ||
              number.substring(0,5) == '02284' ||
              number.substring(0,5) == '02285' ||
              number.substring(0,5) == '02286' ||
              number.substring(0,5) == '02291' ||
              number.substring(0,5) == '02292' ||
              number.substring(0,5) == '02296' ||
              number.substring(0,5) == '02297' ||
              number.substring(0,5) == '02302' ||
              number.substring(0,5) == '02314' ||
              number.substring(0,5) == '02316' ||
              number.substring(0,5) == '02317' ||
              number.substring(0,5) == '02320' ||
              number.substring(0,5) == '02323' ||
              number.substring(0,5) == '02324' ||
              number.substring(0,5) == '02325' ||
              number.substring(0,5) == '02326' ||
              number.substring(0,5) == '02331' ||
              number.substring(0,5) == '02333' ||
              number.substring(0,5) == '02334' ||
              number.substring(0,5) == '02335' ||
              number.substring(0,5) == '02336' ||
              number.substring(0,5) == '02337' ||
              number.substring(0,5) == '02338' ||
              number.substring(0,5) == '02342' ||
              number.substring(0,5) == '02343' ||
              number.substring(0,5) == '02344' ||
              number.substring(0,5) == '02345' ||
              number.substring(0,5) == '02346' ||
              number.substring(0,5) == '02352' ||
              number.substring(0,5) == '02353' ||
              number.substring(0,5) == '02354' ||
              number.substring(0,5) == '02355' ||
              number.substring(0,5) == '02356' ||
              number.substring(0,5) == '02357' ||
              number.substring(0,5) == '02358' ||
              number.substring(0,5) == '02392' ||
              number.substring(0,5) == '02393' ||
              number.substring(0,5) == '02394' ||
              number.substring(0,5) == '02395' ||
              number.substring(0,5) == '02396' ||
              number.substring(0,5) == '02473' ||
              number.substring(0,5) == '02474' ||
              number.substring(0,5) == '02475' ||
              number.substring(0,5) == '02477' ||
              number.substring(0,5) == '02478' ||
              number.substring(0,5) == '02622' ||
              number.substring(0,5) == '02624' ||
              number.substring(0,5) == '02625' ||
              number.substring(0,5) == '02626' ||
              number.substring(0,5) == '02646' ||
              number.substring(0,5) == '02647' ||
              number.substring(0,5) == '02648' ||
              number.substring(0,5) == '02651' ||
              number.substring(0,5) == '02652' ||
              number.substring(0,5) == '02655' ||
              number.substring(0,5) == '02656' ||
              number.substring(0,5) == '02657' ||
              number.substring(0,5) == '02658' ||
              number.substring(0,5) == '02901' ||
              number.substring(0,5) == '02902' ||
              number.substring(0,5) == '02903' ||
              number.substring(0,5) == '02920' ||
              number.substring(0,5) == '02921' ||
              number.substring(0,5) == '02922' ||
              number.substring(0,5) == '02923' ||
              number.substring(0,5) == '02924' ||
              number.substring(0,5) == '02925' ||
              number.substring(0,5) == '02926' ||
              number.substring(0,5) == '02927' ||
              number.substring(0,5) == '02928' ||
              number.substring(0,5) == '02929' ||
              number.substring(0,5) == '02931' ||
              number.substring(0,5) == '02932' ||
              number.substring(0,5) == '02933' ||
              number.substring(0,5) == '02934' ||
              number.substring(0,5) == '02935' ||
              number.substring(0,5) == '02936' ||
              number.substring(0,5) == '02940' ||
              number.substring(0,5) == '02942' ||
              number.substring(0,5) == '02945' ||
              number.substring(0,5) == '02946' ||
              number.substring(0,5) == '02948' ||
              number.substring(0,5) == '02952' ||
              number.substring(0,5) == '02953' ||
              number.substring(0,5) == '02954' ||
              number.substring(0,5) == '02962' ||
              number.substring(0,5) == '02963' ||
              number.substring(0,5) == '02964' ||
              number.substring(0,5) == '02966' ||
              number.substring(0,5) == '02972' ||
              number.substring(0,5) == '02982' ||
              number.substring(0,5) == '02983' ||
              number.substring(0,5) == '03327' ||
              number.substring(0,5) == '03329' ||
              number.substring(0,5) == '03382' ||
              number.substring(0,5) == '03385' ||
              number.substring(0,5) == '03387' ||
              number.substring(0,5) == '03388' ||
              number.substring(0,5) == '03400' ||
              number.substring(0,5) == '03401' ||
              number.substring(0,5) == '03402' ||
              number.substring(0,5) == '03404' ||
              number.substring(0,5) == '03405' ||
              number.substring(0,5) == '03406' ||
              number.substring(0,5) == '03407' ||
              number.substring(0,5) == '03408' ||
              number.substring(0,5) == '03409' ||
              number.substring(0,5) == '03435' ||
              number.substring(0,5) == '03436' ||
              number.substring(0,5) == '03437' ||
              number.substring(0,5) == '03438' ||
              number.substring(0,5) == '03442' ||
              number.substring(0,5) == '03444' ||
              number.substring(0,5) == '03445' ||
              number.substring(0,5) == '03446' ||
              number.substring(0,5) == '03447' ||
              number.substring(0,5) == '03454' ||
              number.substring(0,5) == '03455' ||
              number.substring(0,5) == '03456' ||
              number.substring(0,5) == '03458' ||
              number.substring(0,5) == '03460' ||
              number.substring(0,5) == '03462' ||
              number.substring(0,5) == '03463' ||
              number.substring(0,5) == '03464' ||
              number.substring(0,5) == '03465' ||
              number.substring(0,5) == '03466' ||
              number.substring(0,5) == '03467' ||
              number.substring(0,5) == '03468' ||
              number.substring(0,5) == '03469' ||
              number.substring(0,5) == '03471' ||
              number.substring(0,5) == '03472' ||
              number.substring(0,5) == '03476' ||
              number.substring(0,5) == '03482' ||
              number.substring(0,5) == '03483' ||
              number.substring(0,5) == '03487' ||
              number.substring(0,5) == '03489' ||
              number.substring(0,5) == '03491' ||
              number.substring(0,5) == '03492' ||
              number.substring(0,5) == '03493' ||
              number.substring(0,5) == '03496' ||
              number.substring(0,5) == '03497' ||
              number.substring(0,5) == '03498' ||
              number.substring(0,5) == '03521' ||
              number.substring(0,5) == '03522' ||
              number.substring(0,5) == '03524' ||
              number.substring(0,5) == '03525' ||
              number.substring(0,5) == '03532' ||
              number.substring(0,5) == '03533' ||
              number.substring(0,5) == '03537' ||
              number.substring(0,5) == '03541' ||
              number.substring(0,5) == '03542' ||
              number.substring(0,5) == '03543' ||
              number.substring(0,5) == '03544' ||
              number.substring(0,5) == '03546' ||
              number.substring(0,5) == '03547' ||
              number.substring(0,5) == '03548' ||
              number.substring(0,5) == '03549' ||
              number.substring(0,5) == '03562' ||
              number.substring(0,5) == '03563' ||
              number.substring(0,5) == '03564' ||
              number.substring(0,5) == '03571' ||
              number.substring(0,5) == '03572' ||
              number.substring(0,5) == '03573' ||
              number.substring(0,5) == '03574' ||
              number.substring(0,5) == '03575' ||
              number.substring(0,5) == '03576' ||
              number.substring(0,5) == '03582' ||
              number.substring(0,5) == '03583' ||
              number.substring(0,5) == '03584' ||
              number.substring(0,5) == '03585' ||
              number.substring(0,5) == '03711' ||
              number.substring(0,5) == '03715' ||
              number.substring(0,5) == '03716' ||
              number.substring(0,5) == '03718' ||
              number.substring(0,5) == '03721' ||
              number.substring(0,5) == '03725' ||
              number.substring(0,5) == '03731' ||
              number.substring(0,5) == '03734' ||
              number.substring(0,5) == '03735' ||
              number.substring(0,5) == '03741' ||
              number.substring(0,5) == '03743' ||
              number.substring(0,5) == '03751' ||
              number.substring(0,5) == '03754' ||
              number.substring(0,5) == '03755' ||
              number.substring(0,5) == '03756' ||
              number.substring(0,5) == '03757' ||
              number.substring(0,5) == '03758' ||
              number.substring(0,5) == '03772' ||
              number.substring(0,5) == '03773' ||
              number.substring(0,5) == '03774' ||
              number.substring(0,5) == '03775' ||
              number.substring(0,5) == '03777' ||
              number.substring(0,5) == '03781' ||
              number.substring(0,5) == '03782' ||
              number.substring(0,5) == '03786' ||
              number.substring(0,5) == '03821' ||
              number.substring(0,5) == '03825' ||
              number.substring(0,5) == '03826' ||
              number.substring(0,5) == '03827' ||
              number.substring(0,5) == '03832' ||
              number.substring(0,5) == '03835' ||
              number.substring(0,5) == '03837' ||
              number.substring(0,5) == '03838' ||
              number.substring(0,5) == '03841' ||
              number.substring(0,5) == '03843' ||
              number.substring(0,5) == '03844' ||
              number.substring(0,5) == '03845' ||
              number.substring(0,5) == '03846' ||
              number.substring(0,5) == '03854' ||
              number.substring(0,5) == '03855' ||
              number.substring(0,5) == '03856' ||
              number.substring(0,5) == '03857' ||
              number.substring(0,5) == '03858' ||
              number.substring(0,5) == '03861' ||
              number.substring(0,5) == '03862' ||
              number.substring(0,5) == '03863' ||
              number.substring(0,5) == '03865' ||
              number.substring(0,5) == '03867' ||
              number.substring(0,5) == '03868' ||
              number.substring(0,5) == '03869' ||
              number.substring(0,5) == '03873' ||
              number.substring(0,5) == '03876' ||
              number.substring(0,5) == '03877' ||
              number.substring(0,5) == '03878' ||
              number.substring(0,5) == '03885' ||
              number.substring(0,5) == '03886' ||
              number.substring(0,5) == '03887' ||
              number.substring(0,5) == '03888' ||
              number.substring(0,5) == '03891' ||
              number.substring(0,5) == '03892' ||
              number.substring(0,5) == '03894' ||
              number.substring(0,5) == '03822' ||
              number.substring(0,5) == '03717' ||
              number.substring(0,5) == '03783' ||
              number.substring(0,5) == '03722' ||
              number.substring(0,5) == '02965' ||
              number.substring(0,5) == '03833' ||
              number.substring(0,5) == '02221' ||
              number.substring(0,5) == '03752'
          ){
            formatNet2Phone = number.substring(0,5)+"15"+number.substring(5);
          }else if (
              number.substring(0,4) == '0220' ||
              number.substring(0,4) == '0221' ||
              number.substring(0,4) == '0223' ||
              number.substring(0,4) == '0230' ||
              number.substring(0,4) == '0236' ||
              number.substring(0,4) == '0237' ||
              number.substring(0,4) == '0249' ||
              number.substring(0,4) == '0260' ||
              number.substring(0,4) == '0261' ||
              number.substring(0,4) == '0263' ||
              number.substring(0,4) == '0264' ||
              number.substring(0,4) == '0266' ||
              number.substring(0,4) == '0280' ||
              number.substring(0,4) == '0291' ||
              number.substring(0,4) == '0294' ||
              number.substring(0,4) == '0297' ||
              number.substring(0,4) == '0298' ||
              number.substring(0,4) == '0299' ||
              number.substring(0,4) == '0343' ||
              number.substring(0,4) == '0345' ||
              number.substring(0,4) == '0348' ||
              number.substring(0,4) == '0351' ||
              number.substring(0,4) == '0353' ||
              number.substring(0,4) == '0358' ||
              number.substring(0,4) == '0362' ||
              number.substring(0,4) == '0364' ||
              number.substring(0,4) == '0370' ||
              number.substring(0,4) == '0376' ||
              number.substring(0,4) == '0379' ||
              number.substring(0,4) == '0380' ||
              number.substring(0,4) == '0381' ||
              number.substring(0,4) == '0383' ||
              number.substring(0,4) == '0385' ||
              number.substring(0,4) == '0387' ||
              number.substring(0,4) == '0342' ||
              number.substring(0,4) == '0341' ||
              number.substring(0,4) == '0336' ||
              number.substring(0,4) == '0388'
          ){
            formatNet2Phone = number.substring(0,4)+"15"+number.substring(4);
          }else {
            this.setState({message: "Avisar a sistemas que este número no esta contemplado para zolvex: "+ number});
            this.openModal('modalMessage');
            let jsonEmail = {"to": ["nicolasdariolopezpernia@gmail.com","lopezn@gedco.ar","sistemas@gedco.com.ar","gimeneze@gedco.ar", "gonzalezg@gedco.ar", "german.perezlindo@zolvex.com.ar"], "subject": "Verificar este caso que no se contempla por net2phone", "content": "Error en el numero: "+ number + " del tipo: "+typePhone+ " de la cuenta: "+ this.props.idCuenta + " del dni: "+ this.props.dni , "from": "misconsultas@miscuentasclaras.com.ar"};
            EmailService.sendEmailAWS(jsonEmail);
            return;
          }
        }
      }
      const data = {
        callerid: "011541177004182",
        agent: "operador1",
        phone: this.props.net2phoneNroInterno,
        tech: "SIP",
        context: "agents",
        outqueue: this.props.net2PhoneCampaign, //"Saliente->"
        destination: ""+formatNet2Phone
      }
      this.setState({message: "Marcando en Net2Phone..."});
      this.openModal('modalMessage');
      let responseCall = await Net2PhoneService.callNet2Phone(data, this.props.paisEmpresaOrigen);
      if(responseCall != undefined && responseCall.status == 401){
        this.setState({message: "Cerrar la ventana y volverla abrir para que funcione la llamada"});
      }else {
        setTimeout(()=>this.closeModal('modalMessage'), 5000);
      }
      const parent = window.parent;
      parent.postMessage(JSON.stringify({"number": number,"data": this.state.data, "responseDial": responseCall, "havePhone": true}), "*");
    }else{
      this.setState({message: "Por favor genere una gestión para poder continuar operando."});
      this.openModal('modalMessage');
    }
  }

  onClick(number, uri, typePhone){
    if(this.props.providerCall == "dial"){
      this.callDial(number,uri); 
    }else if(this.props.providerCall == "inconcert") {
      this.callInconcert(number, uri);
    }else if (this.props.providerCall == "net2phone"){
      this.callNet2Phone(number, typePhone);
    }else{
      console.log("Provedor seteado incorectamente")
    } 
  }
  handleKeyUp(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value; 
    if(fields["phone"].match(/^[0-9]+$/)){
      this.refs.savePhone.disabled = false;      
      this.setState({btnSave: false});
    }else{
      this.refs.savePhone.disabled = true;
      this.setState({btnSave: true});
    }
  }
  handleChange(field, e){         
    let fields = this.state.fields;
    fields[field] = e.target.value;       
    this.setState({fields});    
  }
  async registerPhone(){        
    this.setState({btnSave: true});
    var phoneNumber = this.refs.phone.value; 
    let data ={};
    data.source = "operador";
    data.rawinput  = phoneNumber;
    data.dni = this.props.dni;    
    await ClientService.findAllClientByDni(this.props.dni, this.props.paisEmpresaOrigen).then(async function(responseClient) {      
      for (let index = 0; index < responseClient.length; index++) {
        const element = responseClient[index];
        data.account = element.idAccount;   
        data.company = element.idCompany;
        data.countryOrigin = element.countryOrigin
        await PhoneService.savePhone(data).then(async function(response) {     
            if (this.props.idCuenta == element['idAccount']) {
              switch (response.active) {
                case 0:          
                  this.setState({message: "No existe número teléfono ingresado."});
                  this.openModal('modalMessage');
                  break;
                case 1:          
                  this.setState({message: "Se registró el teléfono correctamente."});
                  this.openModal('modalMessage');
                  this.refresh();
                  this.closeModal('modalSave')                   
                  break;
                case -1:          
                  this.setState({message: "El número ingresado ya se encuentra registrado."});
                  this.openModal('modalMessage');          
                  break;
                case -2:          
                  this.setState({message: "No se pudo registrarse el teléfono ingresado."});
                  this.openModal('modalMessage');
                  break;
                  case -3:          
                  this.setState({message: "Número no permitido."});
                  this.openModal('modalMessage');
                  break;
                default:
                  break;
              }
            } 
                  
        }.bind(this)).catch((error) => {      
          this.openModal('modalMessage');
          this.setState({message: "No se pudo registrarse el teléfono ingresado."});      
        }); 
      }
      this.setState({btnSave: false});     
    }.bind(this))    
  }
  async updatePhone(number){
    let data = this.state.data.filter(item => item.number === number);    
    let form = {};     
    form.type = data[0].type;     
    form.number = data[0].number;    
    form.account = data[0].account;    
    if(data[0].active){
      form.active = 1;
    }else{
      form.active = 0;
    }       
    await PhoneService.updatePhone(form).then(async function(response) {      
      switch (response.active) {
        case 0: 
          this.setState({message: "Se actualizó estado correctamente."});
          this.openModal('modalMessage');      
          this.refresh();
          break;
        case 1:
          this.setState({message: "Se actualizó estado correctamente."});
          this.openModal('modalMessage');      
          this.refresh();
          break;
        case -2:          
          this.setState({message: "No se pudo actualizar el estado."});
          this.openModal('modalMessage');
          break;
        default:
          break;
      }      
    }.bind(this)).catch((error) => {
      this.openModal('modalMessage');
      this.setState({message: "No pudo actualizarse en estado"});      
    });          
  } 
  async refresh(){
    var dataPhone = await PhoneService.phoneScore(this.props.idCuenta, this.props.paisEmpresaOrigen);
    this.setState({data: dataPhone}); 
  } 
  render() {      
    return (      
      <div>          
          <button className="btn btn-success" onClick={() => this.openModal('modalSave')}>AGREGAR NUEVO TELEFONO</button>                 
          <br/>  
          <br/> 
          <table className="table table-striped tablePhone">        
            <thead>
            <tr>
              {/* <th>COMENTARIO</th> */}
              <th className="header"><strong>CLICK2CALL</strong></th>
              <th className="header"><strong>SELECCIONAR</strong></th>
              <th className="header"><strong>TELÉFONO</strong></th>
              <th className="header"><strong>TIPO</strong></th>
              <th className="header"><strong>ESTADO CONTACTO</strong></th>
              <th className="header" hidden={!this.props.allowPhoneReference}><strong>REFERENCIA</strong></th>
              <th className="header"><strong>VIGENTE</strong></th>
              <th className="header"><strong>MEJOR FRANJA HORARIA</strong></th>
              <th className="header"><strong>SCORING</strong></th>
              <th className="header"><strong>ORIGEN TELÉFONO</strong></th>
            </tr>
            </thead>
            <tbody>
            {
              this.state.data.map(item => {
                  return (
                    <tr key={item.number}>
                      {/* <td>
                      {item.comments}
                      </td> */}
                      <td>
                      <button className="btn btn-primary" disabled={item.active !== 1 ? 1 : 0} onClick={() => this.onClick(item.number, this.props.uri, item.type_description)}><FontAwesomeIcon icon="phone" /></button>
                      </td>
                      <td>
                        <button className="btn btn-primary" disabled={item.active !== 1 ? 1 : 0}
                                onClick={() => this.selectPhone(item.number)}>
                          <FaCopy />
                        </button>
                      </td>
                      <td style={item.background.styles.one}>
                        <b> {item.number}</b>
                      </td>
                      <td>
                        <b> {item.type_description} </b>
                      </td>
                      <td>
                        <b> {item.status} </b>
                      </td>
                      <td hidden={!this.props.allowPhoneReference} style={{maxWidth: '104px', wordWrap: 'break-word'}}>
                        <b>{item.reference ? item.reference : ''}</b>
                      </td>
                      <td>
                      <input
                            type="checkbox"
                            className="form-check-input"
                            name="active"
                            checked={item.active === 1 ? 1 : 0}
                          onChange={this.handleInputChange} 
                          value={item.number}
                          disabled={((item.active === -1 && this.props.perfil === "operador") || this.props.perfil === "operador")  ? 1 : 0}
                        />            
                      </td>
                      <td>                       
                        <b> {item.besta_description}</b>
                      </td>
                      <td>                       
                        <b> {item.scoring}</b>
                      </td> 
                      <td>                       
                        <b> {item.source}</b>
                      </td>                                
                    </tr>                    
                  );
                })
              }
              <tr>
                <td colSpan="8" hidden={this.state.data.length > 0}><b>Sin resultados</b></td>
              </tr>
            </tbody>
          </table>                                              
         {/* Modal para guardar nuevo teléfono */}
        <Modal
          isOpen={this.state.modalSave}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        > 
          <div className="modal-header">
            <h2 ref={subtitle => this.subtitle = subtitle}><b>NUEVO TELEFONO</b></h2>
            <button onClick={() => this.closeModal('modalSave')} className="close">&times;</button> 
          </div>           
          <div className="modal-body">
            <div className="d-flex flex-row">
              <div className="p-2"><label />TELEFONO:</div>
              <div className="p-2">
                <input 
                  className="form-control input"                    
                  onKeyUp={this.handleKeyUp.bind(this, "phone")} 
                  onChange={this.handleChange.bind(this, "phone")}
                  ref="phone" type="text"/>
                </div>              
            </div>
          </div>
          <div className="modal-footer">            
            <button type="button" className="btn btn-secondary btn-lg" onClick={() => this.closeModal('modalSave')}>Cerrar</button>
            <button type="button" ref="savePhone"  className="btn btn-primary btn-lg" disabled={this.state.btnSave} onClick={() => this.registerPhone()}>Guardar</button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.modalMessage}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"> 
          <div className="modal-header">
            <h2 ref={subtitle => this.subtitle = subtitle}><b>INFORMACIÓN</b></h2> 
            <button onClick={() => this.closeModal('modalMessage')} className="close">&times;</button> 
          </div>           
          <div className="modal-body">
            <div className="d-flex justify-content-start">
             <label/>{this.state.message}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary btn-lg" onClick={() => this.closeModal('modalMessage')}>Cerrar</button>
          </div>
        </Modal>     
      </div>
    );
  }  
}
export default PhoneTable;
